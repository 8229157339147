<template>
  <div class="pay h-backgroud-color_body">
    <br />
    <div v-if="orderTime > 0">
      <div class="box h-text-center">
        <!-- <div class="h-font-xl h-flex h-row-center">
          <van-image v-if="orderTime > 0" :src="require('@/assets/images/icon/success.png')" style="height:20px;width:20px"/>
          <span class="h-m-l-5">{{ orderText }}</span>
        </div> -->
        <div>
          <div class="h-font-sm h-text-secondary-color h-m-t-5">请在<span class="h-price-color h-p-l-5 h-p-r-5">24时之前</span>完成支付</div>
          <div class="h-font-sm h-text-secondary-color h-m-t-2">逾期订单将自动取消</div>
        </div>
      </div>
      <div v-if="orderData" class="box">
        <div class="row h-flex h-row-between">
          <span>支付金额</span>
          <span class="h-price-color">￥{{ orderData.finalSaleAmount | amountFilter }}</span>
        </div>
        <div class="row h-flex h-row-between">
          <span>订单号</span>
          <span>{{ orderData.orderNo }}</span>
        </div>
        <div class="row h-flex h-row-between">
          <span>客户名称</span>
          <span>{{ orderData.customer.customerName }}</span>
        </div>
        <div class="row h-flex h-row-between">
          <span>车牌号</span>
          <span>{{ orderData.vehicle.plateNo }}</span>
        </div>
        <div class="row h-flex h-row-between">
          <span style="width: 100px;">服务名称</span>
          <span style="text-align: right;">{{ orderData.productNames }}</span>
        </div>
      </div>
      <div v-if="preOrderId" class="box">
        <van-radio-group v-model="payWay" @change="payChange">
          <div class="h-flex h-row-between" style="border-bottom: 1px solid #ebedf0;padding-bottom: 15px;">
            <div class="h-flex">
              <van-image :src="require('@/assets/images/icon/weixin_pay.png')" style="height:30px;width:30px" />
              <div class="h-p-l-10">
                <div>微信支付</div>
                <!-- <div class="h-font-sm h-text-secondary-color">微信支付 快捷支付</div> -->
              </div>
            </div>
            <div>
              <van-radio name="WXJS0007MIPP" checked-color="#57ccc2" />
            </div>
          </div>
          <div class="h-flex h-row-between h-m-t-15">
            <div class="h-flex">
              <van-image :src="require('@/assets/images/icon/other_pay.png')" style="height:26px;width:26px;padding-left: 3px;" />
              <div class="h-p-l-10">
                <div>他人支付</div>
                <!-- <div class="h-font-sm h-text-secondary-color">他人支付 快捷支付</div> -->
              </div>
            </div>
            <div>
              <van-radio name="other" checked-color="#57ccc2" />
            </div>
          </div>
        </van-radio-group>
      </div>
      <div class="btn h-flex h-row-around">
        <van-button round type="default" class="h-flex-1 h-m-l-10 h-m-r-10" @click="backOrderList">返回</van-button>
        <van-button v-if="orderTime > 0" round type="primary" class="h-flex-1 h-m-l-10 h-m-r-10" :loading="pageData.payLoading" @click="toPay">立即支付</van-button>
      </div>
      <!-- <van-button type="primary" size="large" round style="margin-top: 20px" @click="shareQRCode">分享他人</van-button> -->
    </div>
    <div v-else>
      <div class="box h-text-center">
        <div>
          <div class="h-font-sm h-text-secondary-color h-m-t-2">该订单已失效不能支付</div>
        </div>
      </div>
    </div>
    <Popup v-model="otherPayVisible" :popup-style="{ height: '380px' }">
      <div v-if="!shareImgUrl" id="shareImgDiv">
        <!-- <div style="background: #fff;padding: 5px;text-align: center;">
          请在 <span style="color: #ff976a;">24时之前</span> 完成支付，逾期订单将自动取消
        </div> -->
        <div style="background: #fff;border-radius: 5px;padding: 5px;padding-bottom: 10px;">
          <div class="row h-flex h-row-between" style="border: 0px;">
            <span>支付金额</span>
            <span class="h-price-color">￥{{ orderData.finalSaleAmount | amountFilter }}</span>
          </div>
          <div class="row h-flex h-row-between" style="border: 0px;margin-bottom: 10px;">
            <span>订单号</span>
            <span>{{ orderData.orderNo }}</span>
          </div>
          <QRCode ref="shareQRCodeRef"></QRCode>
          <div style="color: #ff976a; margin-top: 10px;padding-top: 10px;border-top: 1px solid rgb(235, 237, 240);">长按二维码识别并进行支付</div>
        </div>
      </div>
      <van-image :src="shareImgUrl" />
      <!-- v-else -->
    </Popup>
    <div v-show="showMask" class="mask-cls">
      <div style="font-size: 20px; color: #fff; height: 90px; line-height: 100px;margin-top: 190px;">点击这里分享给朋友吧！</div>
      <div>
        <van-image :src="tipPng" style="position: fixed;top: 7px;right: 30px;width: 150px;" />
      </div>
      <van-button type="primary" size="large" round style="background-color: rgba(0, 0, 0, 0);border: 1px solid #fff;width: 130px;" @click="showMask = false">知道了</van-button>
    </div>
    <div v-show="showShareMask" class="mask-cls">
      <div style="font-size: 18px; color: #fff; height: 100px; line-height: 100px;margin-top: 3rem;">点击右上角分享给朋友吧！</div>
      <div>
        <van-icon name="back-top" color="#fff" size="1rem" />
      </div>
      <van-button type="primary" size="large" round style="background-color: rgba(0, 0, 0, 0.3);border: 1px solid #fff;width: 200px;margin-top: 20px;" @click="showShareMask = false">我知道了</van-button>
    </div>
  </div>
</template>

<script>
import Popup from "@/components/Popup";
import QRCode from "@/components/QRCode";
// import domToImage from "dom-to-image";
import dayjs from "dayjs";
import { request } from "@/api/service";
import { mapState } from "vuex";
import { getConfig } from "@/utils/weixin";
import html2canvas from "html2canvas";
import tipPng from '@/views/product/pay/images/tip.png';
// function isIos() {
//   const u = navigator.userAgent;
//   let ua = u.toLowerCase();
//   return /iphone|ipad|ipod/.test(ua);
// }
export default {
  components: { Popup, QRCode },
  filters: {
    amountFilter(value) {
      if (value) {
        return parseFloat(value).toFixed(2);
      }
      return "0.00";
    }
  },
  data() {
    return {
      tipPng,
      otherPayVisible: false,
      payShareUrl: null,
      showMask: false,
      showShareMask: false,
      shareImgUrl: null,
      pageData: {
        payLoading: false
      },
      orderId: null,
      preOrderId: null,
      payWay: "WXJS0007MIPP",
      interval: null,
      orderTime: 30,
      orderData: null
    };
  },
  computed: {
    ...mapState("user", ["info"])
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { orderId } = this.$route.query;
      if (!orderId) {
        return false;
      }
      this.orderId = orderId;
      try {
        const res = await request({
          url: `/afis-carservice/partner/cs/order/info/${orderId}`,
          method: "GET"
        });
        this.orderData = res;
        const coverages = res.coverages;
        this.orderData.productNames = "";
        if (coverages && coverages.length > 0) {
          coverages && coverages.map(coverage => {
            this.orderData.productNames += coverage.productFeeName;
          });
        }
        // this.calc()
      } catch (e) {}
    },
    backOrderList() {
      this.clearTime();
      this.$router.replace({
        path: "/product/order/list"
      });
    },
    payChange() {
      // 他人支付
      if (this.payWay === "other") {
        this.showMask = true;
        // this.otherPayVisible = true;
        // this.$nextTick(() => {
        //   // 生成二维码
        this.payShareUrl = `${window.location.origin}/#/product/otherPay/middlePage?orderId=${this.orderId}`;
        //   this.$refs.shareQRCodeRef.createQRCode(this.payShareUrl);
        //   // 生成分享
        this.shareQRCode();
        //   // 生成图片
        //   // isIos() &&
        //   //   domToImage
        //   //     .toPng(document.getElementById("shareImgDiv"))
        //   //     .then(dataUrl => {
        //   //       this.shareImgUrl = dataUrl;
        //   //     })
        //   //     .catch(function(error) {
        //   //       console.error(error);
        //   //     });
        //   // !isIos() &&
        //   html2canvas(document.getElementById("shareImgDiv"), {
        //     useCORS: true,
        //     logging: true,
        //     allowTaint: false // 允许跨域（图片跨域相关）
        //   }).then(canvas => {
        //     this.shareImgUrl = canvas.toDataURL().replace("image/png", "image/octet-stream"); // 获取生成的图片的url
        //   });
        // });
      }
    },
    shareQRCode() {
      // this.showShareMask = true;
      // Mock 查询产品详情, 用于分享
      // productCodes: "ASCXDB"
      if (!this.payShareUrl) return;
      getConfig(location.href.split("#")[0], jssdk => {
        wx.config({
          debug: false, // 是否开启调试功能，这里关闭
          appId: jssdk.appId, // appid
          timestamp: parseInt(jssdk.timestamp), // 时间戳
          nonceStr: jssdk.nonceStr, // 生成签名的随机字符串
          signature: jssdk.signature, // 签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData", "onMenuShareAppMessage", "onMenuShareTimeline"]
        });
        wx.ready(async () => {
          wx.checkJsApi({ jsApiList: ["updateAppMessageShareData", "updateTimelineShareData", "onMenuShareAppMessage", "onMenuShareTimeline"] });
          const list = await request({
            url: "/afis-product/pcfg/product/detail/list",
            method: "post",
            data: {
              code: this.orderData.productCodes
            }
          });
          if (list && list.length > 0) {
            const productInfo = list[0];
            // 需在用户可能点击分享按钮前就先调用
            wx.updateAppMessageShareData({
              title: `【${productInfo.name}】请付个款吧！`, // 分享标题
              desc: productInfo.introduction.replace('<p>', '').replace('</p>', ''), // 分享描述
              link: this.payShareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: `${location.origin}/images/wxshare.png`, // 分享图标
              success: () => {
                // 设置成功
                // this.$toast("分享成功");
              },
              cancel: () => {
                // 设置成功
                this.$toast("已取消分享");
              }
            });
          }
        });
      });
    },
    toPay() {
      // 他人支付
      if (this.payWay === "other") {
        this.payChange();
      } else {
        if (this.preOrderId) {
          this.onLinePay();
        } else {
          this.pay();
        }
      }
    },
    pay() {
      this.pageData.payLoading = true;
      request({
        url: `/afis-carservice/order/payment/${this.orderId}`,
        method: "POST",
        data: {}
      })
        .then(res => {
          this.pageData.payLoading = false;
          if (res.type !== "ONLINE") {
            // 扣除成功
            this.$router.replace({
              path: "/product/pay/finish",
              query: {
                orderId: res.orderId,
                orderNo: res.orderNo,
                contractId: res.contractId,
                contractNo: res.contractNo
              }
            });
            return false;
          }
          if (!res.preOrderId) {
            this.$toast("生成预支付单有误");
            return false;
          }
          this.preOrderId = res.preOrderId;
          // this.$toast("余额不足,请在线支付")
          // this.onLinePay();
        })
        .catch(error => {
          this.pageData.payLoading = false;
        });
    },
    onLinePay() {
      this.pageData.payLoading = true;
      request({
        url: "/afis-payment/request/to/pay",
        method: "POST",
        data: {
          cid: this.payWay, // 支付方式
          preOrderId: this.preOrderId,
          // returnUrl:window.location.origin+`/#/product/pay/success?orderId=${this.orderId}`,
          openid: this.info.thirdId
        }
      })
        .then(res => {
          this.pageData.payLoading = false;
          const external = res.external;
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", external, resPay => {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            if (resPay.err_msg === "get_brand_wcpay_request:ok") {
              this.$router.replace({
                path: "/product/pay/success",
                query: {
                  orderId: this.orderData.id,
                  orderNo: this.orderData.orderNo
                }
              });
            } else if (resPay.err_msg === "get_brand_wcpay_request:cancel") {
              this.$toast("已取消支付");
            } else {
              this.$toast("支付失败");
            }
          });
        })
        .catch(error => {
          this.pageData.payLoading = false;
        });
    },
    calc(applyTime) {
      if (this.orderTime === -1) {
        this.clearTime();
        return;
      }
      const nowData = new Date().getTime();
      // 申请时间 + 30分钟的时间戳
      const validTime =
        dayjs(applyTime)
          .add(30, "minute")
          .unix() * 1000;
      if (nowData > validTime) {
        this.orderText = "订单已失效";
        this.orderTime = -1;
      } else {
        this.orderText = "订单提交成功！";
        this.orderTime = 30 - dayjs(new Date(nowData - this.orderData.applyTime)).format("m");
      }
    },
    calcTime() {
      this.interval = setInterval(this.calc, 60 * 1000);
    },
    clearTime() {
      this.interval && clearInterval(this.interval);
    }
  },
  beforeDestroy() {
    this.clearTime();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.pay {
  height: 100vh;
  padding: 0 10px;
  position: relative;
  font-size: 14px;
  .box {
    padding: 10px;
    margin-bottom: 10px;
    background-color: $white;
    border-radius: 5px;
  }
  .row {
    padding: 10px 5px;
    border-bottom: 1px solid #ebedf0;
  }
  .btn {
    margin-top: 30px;
  }
}
.mask-cls {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2222;
}
</style>
